import { BackendMethod } from '@memberspot/shared/model/backend';
import {
  GetChapterProgressDto,
  GetCourseProgressDto,
  UpdateChapterProgressDto,
  UpdateCourseProgressDto,
  UpdatePostProgressDto,
  UpdatePostProgressExtrasDto,
} from '@memberspot/shared/model/school-user';

import { UnsubscribeAllDto } from '../../dtos/auth/unsubscribe-all.dto';
import {
  FingerprintDto,
  LastLoginDto,
  RegisterDeviceDto,
  RemoveDeviceUserDto,
  UpdateProfilePictureDto,
} from '../../dtos/users/school-users/school-users.dto';

const SchoolUserEndpoints = {
  UPDATE_LAST_LOGIN: (schoolId: string, data: LastLoginDto) => ({
    url: `/school-users/lastlogin/${schoolId}`,
    method: BackendMethod.PATCH,
    data,
  }),
  FINGERPRINT: (schoolId: string, data: FingerprintDto) => ({
    url: `/school-users/fingerprint/${schoolId}`,
    method: BackendMethod.POST,
    data,
  }),
  REGISTER_DEVICE: (schoolId: string, data: RegisterDeviceDto) => ({
    url: `/school-users/register-device/${schoolId}`,
    method: BackendMethod.PATCH,
    data,
  }),
  REMOVE_DEVICE: (schoolId: string, data: RemoveDeviceUserDto) => ({
    url: `/school-users/remove-device/${schoolId}`,
    method: BackendMethod.PATCH,
    data,
  }),
  UPDATE_SETTINGS: (schoolId: string, data: UpdateProfilePictureDto) => ({
    url: `/school-users/update-settings/${schoolId}`,
    data,
    method: BackendMethod.PATCH,
  }),
  UPDATE_PROFILE_PICTURE: (
    schoolId: string,
    data: UpdateProfilePictureDto,
  ) => ({
    url: `/school-users/update-profile-picture/${schoolId}`,
    data,
    method: BackendMethod.PATCH,
  }),
  UPDATE_COURSE_PROGRESS: (data: UpdateCourseProgressDto) => ({
    url: `/school-users/update-course-progress`,
    data,
    method: BackendMethod.PATCH,
  }),
  UPDATE_CHAPTER_PROGRESS: (data: UpdateChapterProgressDto) => ({
    url: `/school-user-progress/update-chapter-progress`,
    data,
    method: BackendMethod.PATCH,
  }),
  UPDATE_POST_PROGRESS: (data: UpdatePostProgressDto) => ({
    url: `/school-user-progress/update-post-progress`,
    data,
    method: BackendMethod.PATCH,
  }),
  UPDATE_POST_PROGRESS_EXTRAS: (data: UpdatePostProgressExtrasDto) => ({
    url: `/school-user-progress/update-post-progress-extras`,
    data,
    method: BackendMethod.PATCH,
  }),
  GET_COURSE_PROGRESS: (data: GetCourseProgressDto) => ({
    url: `/school-user-progress/course-progress/${data.schoolId}/${data.courseId}`,
    method: BackendMethod.GET,
  }),
  GET_CHAPTER_PROGRESS: (data: GetChapterProgressDto) => ({
    url: `/school-user-progress/chapter-progress/${data.schoolId}/${data.courseId}/${data.chapterId}`,
    method: BackendMethod.GET,
  }),
  REGISTER_MEMBER: (schoolId: string) => `/school-users/register/${schoolId}`,

  CLAIM_OFFER: (schoolId: string, offerId: string) => ({
    url: `/school-users/claim-offer/${schoolId}/${offerId}`,
    method: BackendMethod.POST,
  }),
  UNSUBSCRIBE: (schoolId: string, data: UnsubscribeAllDto) => ({
    url: `/school-users/unsubscribe/${schoolId}`,
    data,
    method: BackendMethod.POST_UNAUTH,
  }),
};

export const ClientEndpoints = Object.freeze({
  USER: SchoolUserEndpoints,
});
